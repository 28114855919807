<script lang="ts" setup>
import {RouteLocationRaw} from 'vue-router'
import {LocationResource} from '@/resources/LocationResource'

const route = useRoute()
const localePath = useLocalePath()

const props = defineProps<{
  location?: LocationResource
  route?: RouteLocationRaw
}>()

const poster = computed(() => props.location?.images.at(0)?.path)
</script>

<template>
  <article class="maps-location-card" v-if="props.location">
    <figure>
      <img :src="poster" :alt="props.location.name" />
      <figcaption>{{ props.location.name }}</figcaption>
    </figure>
    <p>{{ props.location.address }}, {{ props.location.city }}</p>
    <NuxtLink :to="props.route" :aria-label="props.location.name"></NuxtLink>
  </article>
</template>

<style lang="postcss">
article.maps-location-card {
  position: relative;
  max-width: 200px;
  & figure {
    width: 200px;
    aspect-ratio: 1 / 1;
    margin: 0;
    padding: 0;
    & img {
      display: block;
      height: 200px;
      width: 200px;
      object-fit: cover;
      border-radius: var(--border-radius-big);
    }
    & figcaption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      margin-top: 1rem;
      font-weight: 500;
      color: var(--text-color-secondary);
    }
  }
  & p {
    font-size: 1.6rem;
    margin: 0;
    font-weight: 400;
  }
  & a {
    position: absolute;
    inset: 0;
  }
}
</style>
