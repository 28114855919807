<script lang="ts" setup>
import qs from 'qs'
import styles from '@/helpers/mapsStyle.json'
import { LocationResource } from '~/resources/LocationResource'

const DEFAULT_ZOOM = 10
const props = defineProps<{
  locations: LocationResource[],
  center: string
  zoomLevel?: number
  aspectRatio?: string
}>()
const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()

let map: google.maps.Map
let markers: google.maps.Marker[] = []
let currentInfoWindow: google.maps.InfoWindow
const mapRef = ref<HTMLElement>()

const initMap = async () => {
  if (!mapRef.value || !google) return
  map = new google.maps.Map(mapRef.value, {
    zoom: DEFAULT_ZOOM,
    styles,
    center: { lat: 52.3702157, lng: 4.895167899999933 },
    disableDefaultUI: true,
    zoomControl: true,
  })
}

const query = computed(() => qs.stringify({ ...route.query }))
const getLocationCardRoute = (location: LocationResource) => `${localePath(`/spaces/${location.slug}`)}?${query.value}`

const createInfoWindow = (location: LocationResource) => {
  const image = location?.images.at(0)?.path
  const route = router.resolve(getLocationCardRoute(location)).href
  const img = useImage()
  const imgUrl = image ? img(image ?? '', { width: 200 }) : ''

  return `<article class="maps-location-card">
    <figure>
      <img src="${imgUrl}" :alt="${location.name}" />
      <figcaption>${location.name}</figcaption>
    </figure>
    <p>${location.address}, ${location.city}</p>
    <a href="${route}" :aria-label="props.location.name"></NuxtLink>
  </article>
  `
}

const setMarkers = (locations: LocationResource[]) => {
  if (!map) return

  for (const location of locations) {
    const infowindow = new google.maps.InfoWindow({
      content: createInfoWindow(location),
      ariaLabel: location.name,
    })

    google.maps.event.addListener(map, 'click', function() {
      infowindow.close()
    })

    const marker = new google.maps.Marker({
      map: map,
      position: { lat: +location.latitude, lng: +location.longitude },
      title: location.name,
      animation: google.maps.Animation.DROP,
      icon: '/red-flag.png'
    })

    marker.addListener("click", () => {
      if (currentInfoWindow) {
        currentInfoWindow.close()
      }

      currentInfoWindow = infowindow
      infowindow.open({
        anchor: marker,
        map,
      })
    })

    markers.push(marker)
  }
}

const setCenter = (center?: string) => {
  if (!map) return

  if (center) {
    const [lat, lng] = center.split(',')
    map.setCenter({ lat: +lat, lng: +lng })

  } else if (props.locations.length > 1) {
    const bounds = new google.maps.LatLngBounds();
    for (const marker of markers) {
      const position = marker.getPosition()
      if (!position) continue
      bounds.extend(position);
    }

    map.fitBounds(bounds)

  }
}

const setZoom = () => {
  if (props.zoomLevel) map.setZoom(props.zoomLevel)
}

const resetMarkers = (locations: LocationResource[]) => {
  for (const marker of markers) {
    marker.setMap(null)
  }

  markers = []
  setMarkers(locations)
  setCenter(props.center)
  setZoom()
}


watch(() => props.locations, () => resetMarkers(props.locations))
watch(() => props.center, () => setCenter(props.center))

onMounted(async() => {
  await initMap()
  setMarkers(props.locations)
  setCenter(props.center)
  setZoom()
})
</script>

<template>
  <div id="google-maps" ref="mapRef">
    <MapsLocationCard />
  </div>
</template>

<style lang="postcss" scoped>
  #google-maps {
    width: 100%;
    aspect-ratio: var(--google-aspect-ratio, 3 / 2);
    border-radius: var(--google-border-radius, var(--border-radius-medium));
    overflow: hidden;
  }
</style>
